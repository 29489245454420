<div (click)="menu?.toggle($event)" class="d-flex align-items-center">
  <div class="user-info d-inline-block">
    <span class="d-block user-name">{{ user?.name }}</span>
    <span class="d-block user-email">{{ user?.email }}</span>
    <span class="d-block user-environment">{{ environmentTitle | translate }}</span>
  </div>

  <div class="menu-cta">
    <i class="fas fa-ellipsis-v"></i>
  </div>

  <p-overlayPanel styleClass="cap-overlay-panel header-title-bar"
    #menu appendTo="body">
    <div class="user-menu">
      <ul class="user-menu__items">
        @for (item of (menuItems$ | async); track item) {
          <li [ngClass]="{'d-none': item?.hidden}" (click)="executeCommand(item);menu?.hide()">
            <i [className]="item?.icon"></i>
            <span>{{ item?.label | translate }}</span>
          </li>
        }
      </ul>
    </div>
  </p-overlayPanel>
</div>
