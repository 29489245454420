@if (!(to?.hidden)) {
  <div class="form-group row {{ field?.key }}"
    [class.has-error]="showError"
    [class.disabled-form]="formControl.disabled"
    >
    @if (to?.inlineLabel) {
      <div class="col">
        @if (to?.translateLabel && to?.hideLabel !== true) {
          <label [attr.for]="id">
            <span class="label">{{ to?.translateLabel | observablePipe | async }}</span>
            @if (to?.required && to?.hideRequiredMarker !== true) {
              <span>*</span>
            }
            @if (to.tooltip) {
              <app-form-tooltip [tooltip]="to.tooltip"></app-form-tooltip>
            }
          </label>
        }
      </div>
    }
    <div class="col">
      <div class="d-flex justify-content-between">
        @if (to?.translateLabel && to?.hideLabel !== true && !to?.inlineLabel) {
          <label [attr.for]="id">
            <span class="label">{{ to?.translateLabel | observablePipe | async }}</span>
            @if (to?.required && to?.hideRequiredMarker !== true) {
              <span>*</span>
            }
            @if (to.tooltip) {
              <app-form-tooltip [tooltip]="to.tooltip"></app-form-tooltip>
            }
          </label>
        }
        @if (to?.translateLink && !(to?.disabled)) {
          <label>
            <a class="clickable-label" (click)="to?.onClickCallback()">{{ to?.translateLink }}</a>
            @if (to.tooltip) {
              <app-form-tooltip [tooltip]="to.tooltip"></app-form-tooltip>
            }
          </label>
        }
      </div>
      <ng-template #fieldComponent></ng-template>
      @if (showError) {
        <div class="error-message">
          <i class="fa fa-exclamation-triangle ml-0"></i>
          <formly-validation-message [field]="field"></formly-validation-message>
        </div>
      }
      @if (to?.description) {
        <small
          class="form-text text-muted">
          {{ to?.description | observablePipe | async }}
        </small>
      }
    </div>
  </div>
}
