import { Component, ViewEncapsulation } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { LeadApiService } from '../../../../../features/leads/services/lead-api.service';
import { saveAs } from 'file-saver';
import { ToastService } from '@capturum/ui/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UploadFileComponent extends FieldType {
  constructor(
    private readonly leadApiService: LeadApiService,
    private readonly toastService: ToastService,
    private readonly translateService: TranslateService
  ) {
    super();
  }

  public loadFile(data: Record<string, any>, leadId): void {
    this.leadApiService.uploadQuotePricePDF(data, leadId).subscribe({
      next: () => {
        this.to.QuotePdf = true;
      },
    });
  }

  public downloadFile(leadId: string): void {
    this.leadApiService.downloadFile(leadId).subscribe((response) => {
      if (response) {
        saveAs(response);
      }
    });
  }

  public removeFile(leadId: string): void {
    this.leadApiService.removeFile(leadId).subscribe({
      next: () => {
        this.to.QuotePdf = false;

        const toastTitle = this.translateService.instant('toast.success.title');
        const toastMessage = this.translateService.instant('LeadPortal.lead.quote-price.removed.toast.message');

        this.toastService.success(toastTitle, toastMessage);
      },
    });
  }
}
