@if (title) {
  <div class="header pt-3">
    @if (title) {
      <div class="title">
        {{ title }}
      </div>
    }
    @if (title && showDivider) {
      <hr />
    }
  </div>
}

@if ((formState$ | async); as formState) {
  <div [ngClass]="[wrapperClass]">
    @if (formState === FormState.LOADING) {
      <!-- @TODO: Add skeleton loader when form builder supports loaders as well -->
    } @else {
      <formly-form [model]="model"
        [class]="formlyClass"
        [fields]="fields"
        [options]="options$ | async"
        [form]="form">
      </formly-form>
    }
  </div>
}
