import { Injectable } from '@angular/core';

@Injectable()
export class ChartUtilsService {
  public getOrCreateTooltip(): HTMLElement {
    let tooltipElRef = document.getElementById('chartjs-tooltip');

    if (!tooltipElRef) {
      tooltipElRef = document.createElement('div');
      tooltipElRef.id = 'chartjs-tooltip';
      tooltipElRef.style.position = 'absolute';
      tooltipElRef.style.pointerEvents = 'none';

      const table = document.createElement('table');

      table.style.margin = '0px';

      tooltipElRef.appendChild(table);
      document.body.appendChild(tooltipElRef);
    }

    return tooltipElRef;
  }

  public setTooltipCustomPosition(tooltipElRef: HTMLElement, tooltip: any, chart: any): void {
    const position = chart.canvas.getBoundingClientRect();

    tooltipElRef.style.left = position.left + window.pageXOffset + tooltip.caretX - 100 + 'px';
    tooltipElRef.style.top = position.top + window.pageYOffset + tooltip.caretY + 'px';
    tooltipElRef.style.opacity = '1';
    tooltipElRef.style.fontSize = '14px';
    tooltipElRef.style.fontFamily = 'LGEITextTTF';
  }
}
