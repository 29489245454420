import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe, PercentPipe } from '@angular/common';
import { QuoteValueType } from '@features/dashboard/models/quote-value-type.enum';

@Pipe({
  name: 'pipeFactory',
})
export class PipeFactoryPipe implements PipeTransform {
  constructor(
    private currencyPipe: CurrencyPipe,
    private percentPipe: PercentPipe
  ) {}

  public transform(value: string | number, type?: QuoteValueType): string | number | null {
    switch (type) {
      case QuoteValueType.Currency:
        return typeof value === 'number' ? this.currencyPipe.transform(value, 'EUR', 'symbol', '1.2-2') : value;
      case QuoteValueType.Percent:
        return typeof value === 'number' ? this.percentPipe.transform(value) : value;
      default:
        return value;
    }
  }
}
