import { Component } from '@angular/core';
import { InfoTableColumn, InfoTableColumnType } from '@capturum/ui/info-table';
import { AppRoutes } from '@core/enums/routes.enum';
import { ListIndexMethod } from '@features/dashboard/models/list-index-method.enum';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { LeadStatus } from '@features/leads/models/lead-status.enum';
import { ListRendererUtils } from '@capturum/builders/list-renderer';
import { BaseDataService } from '@core/services/base-data.service';
import { BaseDataKey } from '@core/enums/base-data-key.enum';
import { first, tap } from 'rxjs/operators';
import { Entity } from '@core/enums/entity.enum';
import { BaseDataValueApiModel } from '@capturum/complete';

@Component({
  selector: 'app-leads-to-be-checked',
  templateUrl: './leads-to-be-checked.component.html',
  styleUrls: ['./leads-to-be-checked.component.scss'],
})
export class LeadsToBeCheckedComponent {
  public dependingFilters: string[] = ['dates', 'regions'];
  public tableColumns: InfoTableColumn[] = [
    {
      field: 'customer_type',
      title: this.translateService.instant('LeadPortal.dashboard.widget.customer-type.label'),
      type: InfoTableColumnType.Template,
      sortable: {
        enabled: false,
        direction: 'asc',
      },
    },
    {
      field: 'name',
      title: this.translateService.instant('LeadPortal.dashboard.widget.name.label'),
      sortable: {
        enabled: false,
        direction: 'asc',
      },
    },
    {
      field: 'zip_code',
      title: this.translateService.instant('LeadPortal.distributor.zipcode.label'),
      sortable: {
        enabled: false,
        direction: 'asc',
      },
    },
    {
      field: 'city',
      title: this.translateService.instant('LeadPortal.distributor.city.label'),
      sortable: {
        enabled: false,
        direction: 'asc',
      },
    },
  ];

  public listIndexMethod = ListIndexMethod.LeadsToBeChecked;

  public AppRoutes = AppRoutes;

  public Entity = Entity;

  constructor(
    private readonly translateService: TranslateService,
    private readonly router: Router,
    private readonly baseDataService: BaseDataService
  ) {}

  public redirectToLeads(): void {
    this.baseDataService
      .findByValue(BaseDataKey.LEAD_STATUS, LeadStatus.Check)
      .pipe(
        first(),
        tap((baseDataValue: BaseDataValueApiModel) => {
          if (baseDataValue) {
            void this.router.navigate([AppRoutes.lead], {
              queryParams: ListRendererUtils.generateQueryUrlFilters({
                status_base_data_value_id: [baseDataValue.id],
              }),
              queryParamsHandling: 'merge',
            });
          }
        })
      )
      .subscribe();
  }
}
