import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'instructionalVideoLink',
})
export class InstructionalVideoLinkPipe implements PipeTransform {
  public transform(currentLang: string): string {
    return currentLang === 'nl' ? 'https://youtu.be/kgWqj9IOt9E' : 'https://youtu.be/npWC9YdF5W0';
  }
}
