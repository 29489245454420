import { Injectable } from '@angular/core';
import { MapItem } from '@capturum/ui/api';
import { from } from 'rxjs';
import { BaseDataIndexedDbModel, BaseDataKeyIndexedDbModel, BaseDataValueApiModel } from '@capturum/complete';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BaseDataService {
  constructor(private translateService: TranslateService) {}

  public list(key: string): Observable<MapItem[]> {
    return from(BaseDataIndexedDbModel.query().where({ key }).first()).pipe(this.baseDataValuesToMapItem.bind(this));
  }

  public getBaseDataValues(key: string): Observable<BaseDataValueApiModel[]> {
    return from(BaseDataIndexedDbModel.query().where({ key }).first()).pipe(
      map((baseData) => {
        return baseData.values;
      })
    );
  }

  public findByValue(key: string, value: string): Observable<BaseDataValueApiModel> {
    return from(BaseDataIndexedDbModel.query().where({ key }).first()).pipe(
      map((baseData) => {
        return baseData.values.find((baseDataValue) => {
          return baseDataValue.value === value;
        });
      })
    );
  }

  private baseDataValuesToMapItem(source: Observable<any>): Observable<MapItem[]> {
    const self = this;

    return new Observable((subscriber) => {
      source.subscribe({
        next(value: BaseDataKeyIndexedDbModel): void {
          const values = value.values;

          if (values) {
            // @ts-ignore
            subscriber.next(
              values.map((baseDataValue: any) => {
                return {
                  label: self.translateService.instant(`base-data.${baseDataValue.id}`),
                  value: baseDataValue.id,
                };
              })
            );
          }

          subscriber.complete();
        },
      });
    });
  }
}
