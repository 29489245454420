import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { QuestionnaireComponent } from './pages/questionnaire/questionnaire.component';
import { GeneralInformationStepComponent } from './components/general-information/general-information-step.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { CapturumCalendarModule } from '@capturum/ui/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { ContactInfoComponent } from './components/contact-info/contact-info.component';
import { HeatPompInformationComponent } from './components/heat-pomp-information/heat-pomp-information.component';
import { CalendarModule } from 'primeng/calendar';
import { FinalStepComponent } from './components/final-step/final-step.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { QuestionnaireWizardService } from './services/questionnaire-wizard.service';
import { CapturumDropdownModule } from '@capturum/ui/dropdown';
import { QuestionnaireService } from './services/questionnaire.service';
import { RouterOutlet } from '@angular/router';
import { OwnershipInformationComponent } from './components/general-information/ownershipt-information/ownership-information.component';
import { HouseTypeComponent } from './components/general-information/house-type/house-type.component';
import { ProjectInformationComponent } from './components/project-information/project-information.component';
import { QuestionnaireRoutingModule } from './questionnaire-routing.module';
import { BackButtonComponent } from './shared/back-button/back-button.component';
import { NextButtonComponent } from './shared/next-button/next-button.component';
import { TooltipModule } from 'primeng/tooltip';

@NgModule({
  declarations: [
    QuestionnaireComponent,
    GeneralInformationStepComponent,
    ProgressBarComponent,
    ProjectInformationComponent,
    ContactInfoComponent,
    HeatPompInformationComponent,
    FinalStepComponent,
    OwnershipInformationComponent,
    HouseTypeComponent,
    BackButtonComponent,
    NextButtonComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    CapturumCalendarModule,
    CapturumDropdownModule,
    DropdownModule,
    ReactiveFormsModule,
    CalendarModule,
    ProgressSpinnerModule,
    QuestionnaireRoutingModule,
    RouterOutlet,
    TooltipModule,
  ],
  providers: [QuestionnaireWizardService, QuestionnaireService],
  exports: [QuestionnaireComponent],
})
export class QuestionnaireModule {}
