<app-table-widget-layout
  [dependingFilters]="dependingFilters"
  [tableName]="'LeadPortal.dashboard.widget.best-performing-installers.title' | translate"
  [listIndexMethod]="listIndexMethod"
  [entityName]="Entity.INSTALLER"
  [columnsData]="tableColumns">
  <ng-container headerActions>
    <a class="link" [routerLink]="viewAllInstallersLink">{{
      'LeadPortal.dashboard.widget.lead-status.action.view-all-installers' | translate
    }}</a>
  </ng-container>
</app-table-widget-layout>
