<div class="questionnaire-wrapper">
  <div class="header">
    <div class="header__logo-logo2">
      <img
        src="./assets/images/logo_with_text.png"
        alt="LG Logo"
        class="logo"
      />
      <img
        class="header__logo2"
        src="assets/images/life_is_good.png"
        alt="High Five"
      />
    </div>

    <div class="progress-bar-container">
      <app-progress-bar></app-progress-bar>
    </div>
  </div>
  <div class="questionnaire-container">
    <router-outlet></router-outlet>
  </div>

  <div class="footer">
    Copyright &#169; 2009-2025 LG Electronics. Alle Rechten Voorbehouden. Dit is
    LG Electronics' officiële homepage. Als je verbinding wilt maken met LG
    Corp., of andere LG filialen, <a href="#">Klik alstublieft</a>
  </div>
</div>
