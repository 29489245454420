import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserAgreementComponent } from './components/user-agreement/user-agreement.component';
import { CapturumCheckboxModule } from '@capturum/ui/checkbox';
import { FormsModule } from '@angular/forms';
import { CapturumButtonModule } from '@capturum/ui/button';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [UserAgreementComponent],
  imports: [CommonModule, CapturumCheckboxModule, FormsModule, CapturumButtonModule, TranslateModule],
})
export class UserAgreementModule {}
