import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-next-button',
  templateUrl: './next-button.component.html',
  styleUrls: ['./next-button.component.scss'],
})
export class NextButtonComponent {
  @Input() public disabled = false;
  @Output() public navigationEvent = new EventEmitter<MouseEvent>();
}
