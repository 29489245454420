<cap-info-table [columns]="to?.infoTableColumns"
  [data]="model"
  [pagination]="false"
  [clickable]="false"
  [sortable]="false">
  <ng-template capTemplate="quantity" let-index="index">
    <formly-field [field]="field.fieldGroup[index]"></formly-field>
  </ng-template>

  <ng-template capTemplate="name" let-item="item">
    <span>{{ item.name }}</span>
  </ng-template>

  <ng-template capTemplate="action" let-item="item">
    @if (!(to?.disabled)) {
      <i class="fas fa-trash" (click)="to?.onRemoveCallback(item)"></i>
    }
  </ng-template>
</cap-info-table>
