import { Component, OnInit } from '@angular/core';
import { DestroyBase } from '@capturum/shared';
import { AppRoutes } from '@core/enums/routes.enum';
import { TranslateService } from '@ngx-translate/core';
import { Observable, switchMap } from 'rxjs';
import { first, startWith } from 'rxjs/operators';
import { NgxPermissionsService } from 'ngx-permissions';
import { NavBarItem } from './models/nav-bar-item.model';
import { filterRequiredPermission } from '@core/operators/filter-required-permission.operator';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss'],
})
export class NavigationBarComponent extends DestroyBase implements OnInit {
  public navItems$: Observable<NavBarItem[]>;

  public constructor(
    private readonly translateService: TranslateService,
    private readonly ngxPermissionsService: NgxPermissionsService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.navItems$ = this.translateService.onLangChange.asObservable().pipe(
      startWith({ lang: '', translations: [] }),
      switchMap(() => {
        const allItems = this.getAllMenuItems();

        return this.getFilteredMenuItems(allItems);
      })
    );
  }

  public getFilteredMenuItems(navItems: NavBarItem[]): Observable<NavBarItem[]> {
    return this.ngxPermissionsService.permissions$.pipe(first(), filterRequiredPermission(navItems));
  }

  private getAllMenuItems(): NavBarItem[] {
    return [
      {
        title: this.translateService.instant('LeadPortal.nav.dashboard.title'),
        routerUrl: `/${AppRoutes.dashboard}`,
        icon: 'fas fa-calendar-alt',
        requiredPermission: 'dashboard.show.tenant',
      },
      {
        title: this.translateService.instant('LeadPortal.nav.leads.title'),
        routerUrl: `/${AppRoutes.lead}`,
        icon: 'fas fa-users',
      },
      {
        title: this.translateService.instant('LeadPortal.nav.distributor.title'),
        routerUrl: `/${AppRoutes.distributor}`,
        icon: 'fas fa-building',
        requiredPermission: 'distributor.manage.tenant',
      },
      {
        title: this.translateService.instant('LeadPortal.nav.installer.title'),
        routerUrl: `/${AppRoutes.installer}`,
        icon: 'fas fa-user-hard-hat',
        requiredPermission: 'installer.manage.tenant',
      },
      {
        title: this.translateService.instant('LeadPortal.nav.users.title'),
        routerUrl: `/${AppRoutes.user}`,
        icon: 'fas fa-user',
        requiredPermission: 'user.manage.tenant',
      },
    ];
  }
}
