@if (to.options$ | async; as options) {
  <cap-dropdown [formControl]="formControl"
    [hidden]="to.readonly"
    [options]="options"
    [optionLabel]="to.label_key"
    [optionValue]="to.value_key"
    [placeholder]="to.placeholder || ' '"
    [appendTo]="'body'"
    [readonly]="to.readonly"
    [showClear]="false"
    [withImages]="!!to.image_key"
    [filter]="true">
    <ng-template capTemplate="item" let-item>
      <app-installer-category-icon
      [categoryValue]="categoryValues$ | async | baseDataValueById : item.key"></app-installer-category-icon>
      <span>{{ item.label }}</span>
    </ng-template>
    <ng-template capTemplate="selectedItem" let-item>
      @if (categoryValues$ | async; as categoryValues) {
        <div class="d-flex">
          <app-installer-category-icon
          [categoryValue]="categoryValues | baseDataValueById : item.key"></app-installer-category-icon>
          <span>{{ item.label }}</span>
        </div>
      }
    </ng-template>
  </cap-dropdown>
  <cpb-readonly-container [hidden]="!to.readonly">
    <div class="d-flex">
      <app-installer-category-icon [categoryValue]="categoryValue$ | async"></app-installer-category-icon>
      <span>{{ formControl.value | baseDataValue }}</span>
    </div>
  </cpb-readonly-container>
}
