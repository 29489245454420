<app-page-layout [title]="title">
  @if (backRouteLink) {
    <app-back-to-overview backButton
      [url]="backRouteLink">
    </app-back-to-overview>
  }

  <ng-container buttons>
    <ng-content select="[additionalButtons]"></ng-content>

    @if (formType === FormType.EDIT && (formState === FormState.LOADING || formState === FormState.DISPLAYING)) {
      <div class="d-flex flex-column flex-md-row button-container">
        <ng-content select="[editButtons]"></ng-content>
        @if (!hideEditButton) {
          <cap-button
            [label]="'button.edit' | translate: { entity: entity }"
            class="edit-button"
            styleClass="primary h-100"
            icon="fa fa-pencil-alt"
            (onClick)="onEditClick()">
          </cap-button>
        }
      </div>
    } @else {
      <cap-button [label]="'button.cancel' | translate"
        styleClass="secondary mr-2 my-1 h-100"
        (onClick)="onCancelClick()">
      </cap-button>
      <cap-button [label]="'button.submit' | translate"
        [icon]="formState === FormState.SUBMITTING ? 'fas fa-spinner fa-pulse' : 'fa fa-check'"
        [disabled]="formState === FormState.SUBMITTING"
        styleClass="success my-1 h-100"
        type="submit"
        (onClick)="onSubmitClick()">
      </cap-button>
    }

  </ng-container>

  <ng-content></ng-content>
</app-page-layout>
