import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { CapturumInfoTableComponent, InfoTableColumn } from '@capturum/ui/info-table';
import { BaseFilterClass } from '@core/classes/base-filter.class';
import { ListIndexMethod } from '@features/dashboard/models/list-index-method.enum';
import { DashboardService } from '@features/dashboard/services/dashboard.service';
import { FilterMetadata } from 'primeng/api';
import { FilterTypeConverterUtil } from '@shared/modules/filters/utils/filter-type-converter.util';
import { unwrapResults } from '@core/operators/unwrap-result.operator';
import { Entity } from '@core/enums/entity.enum';
import { Router } from '@angular/router';

@Component({
  selector: 'app-table-widget-layout',
  templateUrl: './table-widget-layout.component.html',
  styleUrls: ['./table-widget-layout.component.scss'],
})
export class TableWidgetLayoutComponent extends BaseFilterClass implements OnInit {
  @ViewChild(CapturumInfoTableComponent, { static: false })
  public infoTable: CapturumInfoTableComponent;

  @Input()
  public listIndexMethod: ListIndexMethod;

  @Input()
  public columnsData: InfoTableColumn[];

  @Input()
  public tableName: string;

  @Input()
  public dependingFilters: string[];

  @Input()
  public entityName: Entity;

  public currentFilters: Record<string, FilterMetadata> = {};

  constructor(
    injector: Injector,
    public apiService: DashboardService,
    private router: Router
  ) {
    super(injector);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.indexMethod = this.listIndexMethod;
  }

  public onRowClick(item: Record<string, any>): void {
    if (item?.id && this.entityName && item?.name) {
      this.router.navigate([
        this.entityName,
        item?.id,
        encodeURIComponent(item.name),
      ]);
    }
  }

  protected handleFilterChange(activeSearch?: string, activeFilters?: Record<string, FilterMetadata>): void {
    if (this.dependingFilters?.length && Object.keys(activeFilters).length) {
      const finalFilters: Record<string, FilterMetadata> = {};

      this.dependingFilters.forEach((filterName) => {
        if (activeFilters[filterName]) {
          finalFilters[filterName] = activeFilters[filterName];
        }
      });

      if (JSON.stringify(this.currentFilters) !== JSON.stringify(finalFilters)) {
        this.currentFilters = finalFilters;

        // @TODO: This overwrites the activeFilters and breaks the filter components. Manually request data for now.
        // this.lazyLoadEvent = {
        //   ...this.lazyLoadEvent,
        //   filters: FilterTypeConverterUtil.toTableFilter(finalFilters),
        // };
        //
        // this.loadTableData(this.lazyLoadEvent);

        this.apiService[this.indexMethod]({
          filters: FilterTypeConverterUtil.toFilterOptions(finalFilters),
        })
          .pipe(unwrapResults())
          .subscribe((response) => {
            this.tableData = response;
          });
      }
    }
  }
}
