<ng-container *ngIf="selectedWizardType | async as wizardType">
  <div class="step-container">
    <div class="step-content">
      <ng-container *ngIf="wizardType === 'airco'; else heatPumpTemplate">
        <app-ownership-information
          [ownerShipInformationForm]="ownerShipInformationForm"
          [installationDateOptions]="installationDateOptions"
          [roomTypeOptions]="roomTypeOptions"></app-ownership-information>
      </ng-container>
    </div>
    <div class="button-container">
      <app-next-button
        (navigationEvent)="moveOnNextStep()"
        [disabled]="
          (selectedWizardType | async) === 'airco' ? this.ownerShipInformationForm.invalid : this.houseTypeForm.invalid
        "></app-next-button>
    </div>
  </div>
</ng-container>

<ng-template #heatPumpTemplate>
  <app-house-type
    (selectOption)="selectOption($event)"
    [houseTypeOptions]="houseTypeOptions"
    [houseTypeForm]="houseTypeForm"></app-house-type>
</ng-template>
