<div class="text-section">
  <h1 class="step-title">Eigendomsgegevens</h1>
  <p class="step-description">Wat is uw behoefte?</p>
</div>

<div class="container-fluid px-0">
  <ng-container [formGroup]="ownerShipInformationForm">
    <div class="row align-items-baseline">
      <div class="col-md-4 control-label">
        <div>Gewenste installatiedatum</div>
      </div>

      <div class="col-md-8 control-field">
        <cap-dropdown
          placeholder="Selecteer"
          [autoDisplayFirst]="false"
          [options]="installationDateOptions"
          appendTo="body"
          class="dropdown-control"
          formControlName="preferred_installation_period"
          [sortAlphabetically]="false">
        </cap-dropdown>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4 control-label">
        <div>Particulier of zakelijk? *</div>
      </div>

      <div class="col-md-8 control-field">
        <cap-dropdown
          placeholder="Selecteer"
          [autoDisplayFirst]="false"
          [options]="privateBusinessOptions"
          appendTo="body"
          class="dropdown-control"
          formControlName="type"
          [sortAlphabetically]="false"
          (change)="privateBusinessChange($event)">
        </cap-dropdown>
      </div>
    </div>

    <div class="row align-items-baseline">
      <div class="col-md-4 control-label">
        <div>Soort ruimte</div>
      </div>

      <div class="col-md-8 control-field">
        <cap-dropdown
          placeholder="Selecteer"
          [autoDisplayFirst]="false"
          [options]="roomTypeFilteredOptions"
          appendTo="body"
          class="dropdown-control"
          formControlName="room_type"
          [sortAlphabetically]="false">
        </cap-dropdown>
      </div>
    </div>
  </ng-container>
</div>
