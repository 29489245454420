import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { AuthService } from '@core/services/auth.service';
import { BaseDataService } from '@core/services/base-data.service';
import { takeUntil } from 'rxjs';
import { DestroyBase } from '@capturum/shared';
import { SettingIndexedDbModel } from '@capturum/complete';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent extends DestroyBase implements OnInit {
  public currentLang: string;
  public currentEnv: string | boolean;
  public brandName: string = 'LG LeadPortal';

  constructor(
    private readonly translateService: TranslateService,
    protected readonly authService: AuthService,
    protected readonly baseDataService: BaseDataService,
    protected readonly cdr: ChangeDetectorRef
  ) {
    super();
    this.currentLang = this.translateService.currentLang;
  }

  public ngOnInit(): void {
    this._checkForLanguageChange();
    this.currentEnv = SettingIndexedDbModel.getValue('general.tenant_name');
  }

  private _checkForLanguageChange(): void {
    this.translateService.onLangChange
      .pipe(
        tap((langEvent: LangChangeEvent) => {
          this.currentLang = langEvent.lang;
          this.cdr.markForCheck();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }
}
