import { DateFormats } from '@core/configs/app-date.config';
import * as moment from 'moment';

export const DateUtilConfig = {
  startOfDay: '00:00:00',
  endOfDay: '23:59:59',
};

export class DateConverterUtil {
  public static toFormat(date: Date | string, format: DateFormats = DateFormats.sendFormat): string {
    return moment(date).format(format);
  }

  public static fromDateToTime(date: Date | string, time: string): Date {
    const timeSegments = DateConverterUtil.splitTimeIntoSegments(time);

    return moment(date)
      .set('hours', timeSegments.hours)
      .set('minutes', timeSegments.minutes)
      .set('seconds', timeSegments.seconds)
      .toDate();
  }

  public static toDateWithTime(
    date: Date | string,
    start: string = DateUtilConfig.startOfDay,
    end: string = DateUtilConfig.endOfDay
  ): Date[] {
    return [DateConverterUtil.fromDateToTime(date, start), DateConverterUtil.fromDateToTime(date, end)];
  }

  public static toTwentyFourHourDate(date: Date | string): string[] {
    return DateConverterUtil.toDateWithTime(date).map((convertedDate) => {
      return DateConverterUtil.toFormat(convertedDate, DateFormats.sendFormatWithTime);
    });
  }

  public static splitTimeIntoSegments(time: string): {
    hours: number;
    minutes: number;
    seconds: number;
  } {
    const [hours, minutes, seconds]: string[] = time.split(':');

    return {
      hours: +hours,
      minutes: +minutes,
      seconds: +seconds,
    };
  }

  public static isDate(value: any): boolean {
    return moment(value, moment.ISO_8601).isValid();
  }

  public static utcToLocal(datetime: string, returnAsString: boolean = false): Date | string {
    const date = moment(datetime);
    const local = moment.utc(date, 'YYYY-MM-DD HH:mm:ss [UTC]').local();

    if (!returnAsString) {
      return local.toDate();
    }

    return local.format('YYYY-MM-DD HH:mm');
  }
}
