import { Pipe, PipeTransform } from '@angular/core';
import { BaseDataValueApiModel } from '@capturum/complete';

@Pipe({
  name: 'baseDataValueById',
})
export class BaseDataValueByIdPipe implements PipeTransform {
  public transform(value: BaseDataValueApiModel[], id: string, property = 'value'): string {
    const item = value?.find((baseDataValue) => {
      return baseDataValue.id === id;
    });

    if (item) {
      return item.value;
    }

    return null;
  }
}
