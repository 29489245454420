import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map, mergeMap, take } from 'rxjs/operators';
import { InfoTableConfig, InfoTableConfigService } from '@capturum/ui/info-table';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-basic-layout',
  templateUrl: './basic-layout.component.html',
  styleUrls: ['./basic-layout.component.scss'],
})
export class BasicLayoutComponent {
  public showNavbar = false;

  constructor(
    private readonly activatedRoute: ActivatedRoute,
    private readonly router: Router,
    private translateService: TranslateService,
    private infoTableConfigService: InfoTableConfigService,
    private readonly primeNgConfig: PrimeNGConfig
  ) {
    this.primeNgConfig.setTranslation({
      clear: this.translateService.instant('calendar-locale.clear'),
    });

    this.translateService.onLangChange.subscribe(({ translations }) => {
      this.primeNgConfig.setTranslation({
        clear: translations['calendar-locale.clear'],
      });
    });

    this.router.events
      .pipe(
        filter((event) => {
          return event instanceof NavigationEnd;
        }),
        map(() => {
          return this.activatedRoute;
        }),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }

          return route;
        }),
        filter((route) => {
          return route.outlet === 'primary';
        }),
        mergeMap((route) => {
          return route.data;
        })
      )
      .subscribe((event) => {
        this.showNavbar = event?.showNavbar ?? false;
      });

    this.infoTableConfigService
      .getConfig()
      .pipe(filter(Boolean), take(1))
      .subscribe((config: InfoTableConfig) => {
        this.infoTableConfigService.setConfig({
          ...config,
          defaultTexts: {
            ...config.defaultTexts,
            noResults: this.translateService.instant('LeadPortal.list.no-result'),
          },
        });
      });
  }
}
