import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { BaseDataService } from '@core/services/base-data.service';
import { BaseDataKey } from '@core/enums/base-data-key.enum';
import { Observable } from 'rxjs';
import { map, shareReplay, switchMap } from 'rxjs/operators';
import { BaseDataValueApiModel } from '@capturum/complete';

@Component({
  selector: 'app-installer-category-dropdown',
  templateUrl: './installer-category-dropdown.component.html',
  styleUrls: ['./installer-category-dropdown.component.scss'],
})
export class InstallerCategoryDropdownComponent extends FieldType implements OnInit {
  public categoryValue$: Observable<string>;
  public categoryValues$: Observable<BaseDataValueApiModel[]>;

  constructor(private baseDataService: BaseDataService) {
    super();
  }

  public ngOnInit(): void {
    this.categoryValues$ = this.baseDataService.getBaseDataValues(BaseDataKey.INSTALLER_CATEGORY).pipe(shareReplay(1));

    this.categoryValue$ = this.formControl.valueChanges.pipe(
      switchMap((formValue) => {
        return this.categoryValues$.pipe(
          map((categoryValues) => {
            return (
              categoryValues.find((categoryValue) => {
                return categoryValue.id === formValue;
              })?.value || null
            );
          })
        );
      })
    );
  }
}
