import { Component } from '@angular/core';
import { InfoTableColumn, InfoTableColumnType } from '@capturum/ui/info-table';
import { AppRoutes } from '@core/enums/routes.enum';
import { ListIndexMethod } from '@features/dashboard/models/list-index-method.enum';
import { TranslateService } from '@ngx-translate/core';
import { Entity } from '@core/enums/entity.enum';

@Component({
  selector: 'app-best-performing-installers',
  templateUrl: './best-performing-installers.component.html',
  styleUrls: ['./best-performing-installers.component.scss'],
})
export class BestPerformingInstallersComponent {
  public dependingFilters: string[] = ['dates', 'distributor_id', 'regions', 'utm'];
  public viewAllInstallersLink: string = `/${AppRoutes.installer}`;
  public tableColumns: InfoTableColumn[] = [
    {
      field: 'name',
      title: this.translateService.instant('LeadPortal.entity.installer'),
      sortable: {
        enabled: false,
        direction: 'asc',
      },
    },
    {
      field: 'rating',
      title: this.translateService.instant('LeadPortal.dashboard.widget.rating.title'),
      type: InfoTableColumnType.Template,
      sortable: {
        enabled: false,
        direction: 'asc',
      },
    },
  ];

  public listIndexMethod = ListIndexMethod.BestPerformingInstallers;

  public AppRoutes = AppRoutes;

  public Entity = Entity;

  constructor(private readonly translateService: TranslateService) {}
}
