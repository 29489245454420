import { Component, OnInit } from '@angular/core';
import { VersionCheckService } from '@capturum/complete';
import { environment } from '@environments/environment';
import { CSSVarNames, ThemeService } from '@capturum/ui/api';
import { Confirmation, ConfirmationService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public confirmation: Confirmation;

  constructor(
    private readonly versionCheckService: VersionCheckService,
    private readonly themeService: ThemeService,
    private readonly translateService: TranslateService,
    private readonly confirmationService: ConfirmationService
  ) {}

  public ngOnInit(): void {
    this.fetchVersionChanging();
    this.setTheme();

    this.confirmationService.requireConfirmation$.subscribe((result) => {
      return (this.confirmation = result);
    });
  }

  private setTheme(): void {
    this.themeService.setProps({
      [CSSVarNames.Primary]: '#ad0a34',
      [CSSVarNames.Secondary]: '#51001e',
      [CSSVarNames.Success]: '#4cd964',
      [CSSVarNames.Warning]: '#ff9500',
      [CSSVarNames.Error]: '#ff3b30',
      [CSSVarNames.Text]: '#3C3C3B',
      [CSSVarNames.Font]: 'LGEITextTTF',
      [CSSVarNames.DynamicFilterBorderRadius]: '20px',
      [CSSVarNames.DynamicFilterBorderWidth]: '2px',
      [CSSVarNames.DynamicFilterInputHeight]: '33px',
      [CSSVarNames.DynamicFilterPlaceholderFontColor]: '#b2b2b2',
      [CSSVarNames.DynamicFilterIconColor]: '#edcfd7',
      [CSSVarNames.DynamicFilterLabelFontSize]: '0.9rem',
      [CSSVarNames.DynamicFilterWidth]: '250px',
      [CSSVarNames.DynamicFilterLabelFontWeight]: '600',
      [CSSVarNames.DynamicFilterPlaceholderFontWeight]: '500',
    });
  }

  private fetchVersionChanging(): void {
    this.versionCheckService.initVersionCheck(`${environment.url}/version.json`, 10000);

    this.versionCheckService.onUpdateVersion.subscribe(() => {
      this.reloadWindow();
    });
  }

  private reloadWindow(): void {
    window.location.reload();
  }
}
