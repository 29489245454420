import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PrivateBusiness } from '../../../models/private-business.model';

@Component({
  selector: 'app-ownership-information',
  templateUrl: './ownership-information.component.html',
  styleUrls: ['./ownership-information.component.scss'],
})
export class OwnershipInformationComponent implements OnInit {
  @Input() public ownerShipInformationForm: FormGroup;
  @Input() public installationDateOptions: { label: string; value: string }[] =
    [];

  @Input() public roomTypeOptions = {};

  public roomTypeFilteredOptions: { label: string; value: string }[] = [];

  public privateBusinessOptions = [
    { label: 'Particulier', value: PrivateBusiness.PRIVATE },
    { label: 'Zakelijk', value: PrivateBusiness.BUSINESS },
  ];

  public ngOnInit(): void {
    const typeValue = this.ownerShipInformationForm.get('type').value;

    if (typeValue) {
      this.roomTypeFilteredOptions = this.roomTypeOptions[typeValue];
    }
  }

  public privateBusinessChange(event: {
    originalEvent: PointerEvent;
    value: string;
  }): void {
    this.roomTypeFilteredOptions = this.roomTypeOptions[event.value];
    this.ownerShipInformationForm.get('room_type').setValue(null);
  }
}
