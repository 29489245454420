<div class="card d-flex flex-row mb-3">
  <app-installer-category-icon
  [categoryValue]="categoryValues$ | async | baseDataValueById : installerDetails?.current_status"></app-installer-category-icon>
  <div class="d-flex flex-column">
    <span class="label">{{ 'LeadPortal.dashboard.widget.current-status.title' | translate }}</span>
    <span class="title"> {{ installerDetails?.current_status ?
      ( 'base-data.' + installerDetails?.current_status | translate ) :
    ( 'LeadPortal.dashboard.widget.no-status.label' | translate ) }}</span>
  </div>
</div>

<app-card-layout [title]="'LeadPortal.dashboard.widget.rating.title' | translate" disableHeaderSpacing="true">
  @for (data of ratings; track data) {
    <div
      class="rating-wrapper">
      <span>{{ data?.title | translate }}</span>
      <app-star-rating [rating]="data?.rating" [disabled]="true"></app-star-rating>
    </div>
  }
</app-card-layout>
