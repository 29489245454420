import { FilterMetadata } from 'primeng/api';
import { DateConverterUtil } from '@core/utils/date-converter.util';
import { FilterRequest, FilterRequestOptions } from '../models/filter-request.model';
import { FilterMatchMode } from '@capturum/ui/api';
import { DateFormats } from '@core/configs/app-date.config';

export class FilterTypeConverterUtil {
  public static toRecord(filter: FilterRequest | FilterRequest[]): Record<string, FilterMetadata> {
    return Array.isArray(filter)
      ? FilterTypeConverterUtil.fromRequestsToRecord(filter)
      : FilterTypeConverterUtil.fromRequestToRecord(filter);
  }

  public static toFilterRequest(filters: Record<string, FilterMetadata>): FilterRequest[] {
    return Object.entries(filters).map(([field, metadata]) => {
      return {
        field,
        value: metadata.value,
        matchMode: metadata.matchMode,
      };
    });
  }

  public static toFilterOptions(filters: Record<string, FilterMetadata>): FilterRequestOptions[] {
    return FilterTypeConverterUtil.toFilterRequest(filters).map((filterRequest) => {
      let value = filterRequest.value;

      if (filterRequest.matchMode === FilterMatchMode.LIKE) {
        value = `%${filterRequest.value}%`;
      }

      if (Array.isArray(value)) {
        value = value.map((valueItem) => {
          if (DateConverterUtil.isDate(valueItem)) {
            return DateConverterUtil.toFormat(valueItem, DateFormats.sendFormatWithTime);
          }

          if (typeof valueItem === 'object') {
            return valueItem.key;
          }

          return valueItem;
        });
      }

      return {
        field: filterRequest.field,
        value,
        operator: filterRequest.matchMode,
      };
    });
  }

  public static toTableFilter(filters: Record<string, FilterMetadata>): Record<string, FilterMetadata> {
    return Object.entries(filters).reduce((accumulator, [field, filterMetadata]) => {
      let transformedFilters = { [field]: filterMetadata };

      if (DateConverterUtil.isDate(filterMetadata.value)) {
        transformedFilters = {
          [field]: {
            value: DateConverterUtil.toTwentyFourHourDate(filterMetadata.value),
            matchMode: FilterMatchMode.BETWEEN,
            operator: filterMetadata.operator,
          },
        };
      }

      if (Array.isArray(filterMetadata.value)) {
        filterMetadata.value = filterMetadata.value.map((valueItem) => {
          if (DateConverterUtil.isDate(valueItem)) {
            return DateConverterUtil.toFormat(valueItem, DateFormats.sendFormatWithTime);
          }

          if (typeof valueItem === 'object') {
            return valueItem.key;
          }

          return valueItem;
        });
      }

      return { ...accumulator, ...transformedFilters };
    }, {});
  }

  public static toFilterMetadata(filter: FilterRequest, operator: string = 'and'): FilterMetadata {
    return { value: filter.value, matchMode: filter.matchMode, operator };
  }

  private static fromRequestToRecord(filter: FilterRequest): Record<string, FilterMetadata> {
    return { [filter.field]: FilterTypeConverterUtil.toFilterMetadata(filter) };
  }

  private static fromRequestsToRecord(filters: FilterRequest | FilterRequest[]): Record<string, FilterMetadata> {
    const filtersArray = Array.isArray(filters) ? filters : [filters];

    return filtersArray.reduce(
      (previousValue, currentValue) => {
        return {
          ...previousValue,
          ...FilterTypeConverterUtil.fromRequestToRecord(currentValue),
        };
      },
      {} as Record<string, FilterMetadata>
    );
  }
}
