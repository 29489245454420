<img src="assets/images/logo_with_text.png" alt="lg logo" width="115px">

<div class="card container">
  @if (!errorMessage) {
    @if (!tokenIsExpired) {
      @if ((statusFields$ | async); as statusFields) {
        <section class="mb-5" data-test="statusTemplate">
          <div class="row">
            <div class="col">
              <h1 class="title">{{ statusFields.title }}</h1>
            </div>
          </div>
          @for (statusField of (statusFields.main | keyvalue); track statusField) {
            <ng-template *ngTemplateOutlet="detailRow; context: {$implicit: statusField}"></ng-template>
          }
        </section>
        <section>
          <div class="row">
            <div class="col">
              <h1 class="title">{{ 'LeadPortal.lead.proposal-status.lead-details.title' | translate }}</h1>
            </div>
          </div>
          @for (statusField of (statusFields.extra | keyvalue); track statusField) {
            <ng-template *ngTemplateOutlet="detailRow; context: {$implicit: statusField}"></ng-template>
          }
        </section>
      }
    } @else {
      <p class="title" data-test="tokenExpired">{{ 'LeadPortal.lead.proposal-status.token-expired.title' | translate }}</p>
      <p data-test="tokenExpired">{{ 'LeadPortal.lead.proposal-status.token-expired.text' | translate }}</p>
      <p>
        <span data-test="tokenExpired">{{ 'LeadPortal.lead.proposal-status.token-expired.callable' | translate }}</span>
        <a href="/auth/login">LG Leadplatform</a>.
      </p>
    }
  } @else {
    <p class="title">{{ 'LeadPortal.lead.proposal-status.error.title' | translate }}</p>
    <p>{{ errorMessage }}</p>
    <p>
      <span>{{ 'LeadPortal.lead.proposal-status.token-expired.callable' | translate }}</span>
      <a href="/auth/login">LG Leadplatform</a>.
    </p>
  }
</div>

<ng-template #error>
  <p class="title">{{ 'LeadPortal.lead.proposal-status.error.title' | translate }}</p>

  <p>{{ errorMessage }}</p>

  <p>
    <span>{{ 'LeadPortal.lead.proposal-status.token-expired.callable' | translate }}</span>
    <a href="/auth/login">LG Leadplatform</a>.
  </p>
</ng-template>

<ng-template #tokenExpired>
  <p class="title" data-test="tokenExpired">{{ 'LeadPortal.lead.proposal-status.token-expired.title' | translate }}</p>
  <p data-test="tokenExpired">{{ 'LeadPortal.lead.proposal-status.token-expired.text' | translate }}</p>
  <p>
    <span data-test="tokenExpired">{{ 'LeadPortal.lead.proposal-status.token-expired.callable' | translate }}</span>
    <a href="/auth/login">LG Leadplatform</a>.
  </p>
</ng-template>

<ng-template #detailRow let-item>
  <div class="row">
    <div class="col-4 text-nowrap">
      {{ 'LeadPortal.lead.proposal-status.label.' + item.key | translate}}<span>:&nbsp;</span>
    </div>

    <div class="col-8">
      {{ item.value }}
    </div>
  </div>
</ng-template>
