import { TranslateService } from '@ngx-translate/core';
import { PublicTranslationResolver, PublicTranslationService } from '@capturum/complete';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class LgPublicTranslationResolver extends PublicTranslationResolver {
  public constructor(
    private readonly translateService: TranslateService,
    private readonly publicTranslateService: PublicTranslationService
  ) {
    super(publicTranslateService);
  }

  public resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const locale = window.location.host.includes('.es') ? 'es' : 'en';

    this.publicTranslateService.loadFilteredTranslations([], locale).subscribe((res) => {
      this.translateService.setTranslation(locale, res);
    });

    return of(true);
  }
}
