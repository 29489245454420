import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: StarRatingComponent,
      multi: true,
    },
  ],
})
export class StarRatingComponent implements ControlValueAccessor, OnInit {
  @Input() public rating: number;
  @Input() public disabled = false;
  @Input() public showRatingDigits = true;
  @Input() public formControlName: string;

  @Output() public onRatingSelect: EventEmitter<number> = new EventEmitter<number>();

  public stars: number[];

  private onTouched!: (...args: any) => void;
  private onChanged!: (...args: any) => void;

  public ngOnInit(): void {
    this.stars = new Array(5);
  }

  public onStarClick(index: number): void {
    if (this.disabled) {
      return;
    }

    const newRating = index + 1;

    this.onTouched();
    this.rating = newRating;
    this.onRatingSelect.emit(newRating);
    this.onChanged(newRating);
  }

  public writeValue(value: number): void {
    this.rating = value;
  }

  public registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
