import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { AuthConfig, AuthService } from '@capturum/auth';

@Injectable({ providedIn: 'root' })
export class LGEAuthGuard {
  constructor(
    private authService: AuthService,
    private router: Router,
    private config: AuthConfig
  ) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.authService.isAuthenticated()) {
      if (route.queryParams.redirectUrl) {
        this.router.navigate([route.queryParams.redirectUrl]);
      }

      return true;
    }

    this.router.navigate([this.config.authRejectionRoute], {
      queryParams: { redirectUrl: window.location.pathname },
    });

    return false;
  }

  public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }
}
