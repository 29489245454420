<div class="d-flex align-items-center" id="toggleContainer">
  <p-inputSwitch [formControl]="formControl | castTo: FormControl"
    [disabled]="to?.disabled"
    [readonly]="to?.disabled">
  </p-inputSwitch>

  @if (to?.disabled && to?.textLabel) {
    <p class="ml-2">
      {{ to?.textLabel}}
    </p>
  }
</div>
