<div class="container">
  <div class="row px-5 d-md-flex align-items-center">
    <div class="col-md-2 p-0 d-flex flex-column header">
      <ng-content select="[backButton]"></ng-content>

      @if (title) {
        <div class="title">
          <h2>{{ title | observablePipe | async }}</h2>
        </div>
      }
    </div>

    <div class="col-md-10 px-0 py-2 d-md-flex align-items-center justify-content-md-end button-container">
      <ng-content select="[buttons]"></ng-content>
    </div>
  </div>
</div>
