<nav class="navbar navbar-static-top header d-flex" role="navigation">
  <div class="container container-header" id="headerContainer">
    <div class="row g-0 align-items-center w-100">
      <div class="col d-flex justify-content-start">
        <a [routerLink]="homePageUrl" class="logo">
          <img [src]="'logo_with_text.png' | assets: 'images'" alt="logo" class="align-self-center" />

          <span class="title">{{ appName }} - {{ title | translate }}</span>
        </a>
      </div>

      <div class="col d-flex justify-content-end menu">
        <app-user-menu></app-user-menu>
      </div>
    </div>
  </div>

  @if (showNavbar) {
    <div class="container-fluid p-0">
      <div class="row g-0 align-items-center w-100">
        <div class="col">
          <app-navigation-bar></app-navigation-bar>
        </div>
      </div>
    </div>
  }
</nav>
