<div class="rating">
  @if (rating || formControlName) {
    <div class="d-flex">
      @for (star of stars; track star; let index = $index; let last = $last) {
        <i
          [rating]="rating"
          [readonly]="disabled"
          [currentStarIndex]="index + 1"
          [attr.data-test]="'star-rating-' + index"
          appStarRating
          (click)="onStarClick(index)">
        </i>
      }
      @if (disabled && showRatingDigits) {
        <div class="text-rating">
          ({{ (+rating)?.toFixed(2) }})
        </div>
      }
    </div>
  } @else {
    <span class="no-rating">{{ 'LeadPortal.installer.no-rating.label' | translate }}</span>
  }
</div>

