<app-card-layout [title]="'LeadPortal.dashboard.widget.lead-status.title' | translate">
  <ng-container header>
    <a class="link" [routerLink]="'/' + AppRoutes.lead">{{
    'LeadPortal.dashboard.widget.lead-status.action.view-all-leads' | translate }}</a>
  </ng-container>

  <div class="chart-wrapper">
    <div class="d-flex flex-column align-center doughnut-text-centered">
      <span class="label">{{ 'LeadPortal.dashboard.widget.lead-status.total-leads' | translate }}</span>
      <span class="total-lead-count">{{ leadStatuses?.total_lead_count }}</span>
    </div>

    <p-chart #chart type="doughnut" [data]="data" [options]="chartOptions"></p-chart>

    <div class="legend">
      @for (legendItem of legendItems; track legendItem) {
        <div class="legend-item">
          <div class="legend-color" [ngStyle]="{ backgroundColor: legendItem?.backgroundColor }"></div>
          <span>{{ legendItem?.name }}</span>
        </div>
      }
    </div>
  </div>
</app-card-layout>
