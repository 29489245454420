import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-form-tooltip',
  templateUrl: './form-tooltip.component.html',
  styleUrls: ['./form-tooltip.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormTooltipComponent {
  @Input()
  public tooltip: string;
}
