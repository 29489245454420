import { Component, Injector, OnInit } from '@angular/core';
import { InstallerStatus } from '@features/dashboard/models/installer-status.model';
import { InstallerRating } from '@features/dashboard/models/installer-rating.model';
import { DashboardService } from '@features/dashboard/services/dashboard.service';
import { BaseFilterClass } from '@core/classes/base-filter.class';
import { ListOptions } from '@capturum/api/lib/interfaces/api.interface';
import { FilterTypeConverterUtil } from '@shared/modules/filters/utils/filter-type-converter.util';
import { FilterMetadata } from 'primeng/api';
import { Observable } from 'rxjs';
import { BaseDataValueApiModel } from '@capturum/complete';
import { BaseDataService } from '@core/services/base-data.service';
import { BaseDataKey } from '@core/enums/base-data-key.enum';
import { shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-installer-status',
  templateUrl: './installer-status.component.html',
  styleUrls: ['./installer-status.component.scss'],
})
export class InstallerStatusComponent extends BaseFilterClass implements OnInit {
  public installerDetails: InstallerStatus;

  public ratings: InstallerRating[];

  public categoryValues$: Observable<BaseDataValueApiModel[]>;

  constructor(
    injector: Injector,
    private dashboardService: DashboardService,
    private baseDataService: BaseDataService
  ) {
    super(injector);
  }

  public ngOnInit(): void {
    this.getInstallerDetails({});

    this.categoryValues$ = this.baseDataService.getBaseDataValues(BaseDataKey.INSTALLER_CATEGORY).pipe(shareReplay(1));
  }

  public getInstallerDetails(filterOptions?: ListOptions): void {
    this.dashboardService.getInstallerDetails(filterOptions).subscribe((data) => {
      this.installerDetails = data;

      this.ratings = [
        {
          title: 'LeadPortal.dashboard.widget.rating.average.title',
          rating: data?.rating?.average,
        },
        {
          title: 'LeadPortal.dashboard.widget.rating.installment.title',
          rating: data?.rating?.installment,
        },
        {
          title: 'LeadPortal.dashboard.widget.rating.service.title',
          rating: data?.rating?.service,
        },
      ];
    });
  }

  protected handleFilterChange(activeSearch?: string, activeFilters?: Record<string, FilterMetadata>): void {
    const listOptions: ListOptions = {
      filters: FilterTypeConverterUtil.toFilterOptions(activeFilters),
      search: activeSearch,
    };

    this.getInstallerDetails(listOptions);
  }
}
