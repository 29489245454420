import { Component, ViewEncapsulation } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { LoginLayoutComponent as BpLoginLayoutComponent } from '@capturum/login';

@Component({
  selector: 'app-login',
  templateUrl: 'login-layout.component.html',
  styleUrls: ['./login-layout.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('flyDown', [
      state(
        'in',
        style({
          opacity: 1,
          transform: 'translateY(0)',
        })
      ),
      transition('void => *', [
        style({
          opacity: 0,
          transform: 'translateY(-100%)',
        }),
        animate('600ms ease-in'),
      ]),
      transition('* => void', [
        animate(
          '200ms ease-out',
          style({
            opacity: 0,
            transform: 'translateY(-100%)',
          })
        ),
      ]),
      transition(':leave', [
        animate(
          '200ms ease-out',
          style({
            opacity: 0,
            transform: 'translateY(-100%)',
          })
        ),
      ]),
    ]),
  ],
})
export class LoginLayoutComponent extends BpLoginLayoutComponent {}
