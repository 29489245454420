import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { FormState } from '@core/enums/form-state.enum';
import { FormStateService } from '@core/services/form-state.service';

@Component({
  selector: 'app-editable-form-layout',
  templateUrl: './editable-form-layout.component.html',
  styleUrls: ['./editable-form-layout.component.scss'],
})
export class EditableFormLayoutComponent {
  @Input() public title: string | Observable<string>;
  @Input() public showDivider = true;
  @Input() public wrapperClass = '';
  @Input() public model: any;
  @Input() public form: UntypedFormGroup;
  @Input() public fields: FormlyFieldConfig[];
  @Input() public formlyClass = '';

  public FormState: typeof FormState = FormState;
  public formState$ = this.formStateService.onFormStateChange;
  public options$: Observable<FormlyFormOptions> = this.formStateService.options;

  constructor(private readonly formStateService: FormStateService) {}
}
