<div class="back-btn text-left mb-2 d-flex align-items-center">
  <a [routerLink]="url" class="d-flex align-center">
    <i class="fal fa-angle-left"></i>

    @if (text) {
      {{ text | observablePipe | async }}
    } @else {
      {{ 'button.back-to-overview' | translate }}
    }

  </a>
</div>
